<template>
  <div class="navbar is-flex justify-space-between align-items-center">
    <div class="icon-wrapper" @click="$emit('onLeftIconClick')">
      <img class="" src="@/assets/icon/burger.svg" width="24"/>
    </div>
    <div class="icon-wrapper" @click="$emit('onRightIconClick')">
      <!-- <img class="" src="@/assets/icon/user.svg" width="24"/> -->
      <img class="img-profile"
        :src="user && user.line_picture_url || 'https://www.w3schools.com/w3images/avatar2.png'"
        @error="replaceByDefault"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('Auth', ['user'])
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = 'https://www.w3schools.com/w3images/avatar2.png'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.navbar {
  height: 80px;
  background: $home-header;
  padding: 24px 20px;
  .icon-wrapper {
    height: 40px;
    width: 40px;
    background: white;
    border: 1px solid $grey97;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999999px;
    .img-profile {
      height: 100%;
      widows: 100%;
      object-fit: cover;
      border-radius: 999999px;
    }
  }
}
</style>
