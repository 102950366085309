<template>
  <div class="base-modal">
    <div class="header-modal">
      <div class="icon" v-if="isClose" @click="$parent.close()">
        <img src="@/assets/icon/x.svg"/>
      </div>
    </div>
    <div class="body-modal" :class="{
      'has-padding': hasPadding
    }"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    isClose: {
      type: Boolean,
      default: false
    },
    hasPadding: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.base-modal {
  padding: 0 14px;
  width: 100%;
}
.header-modal {
  height: 32px;
  width: 100%;
  background-color: white;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  font-size: 2.1rem;
  font-weight: 500;
  position: relative;
}

.body-modal {
  background: white;
  min-height: 200px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  &.has-padding {
  padding: 0 32px 32px 32px;
}
}
.icon {
  position: absolute;
  right: 16px;
  top: 16px;
  height: 20px;
  width: 20px;
}
</style>
