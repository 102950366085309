<template>
  <div class="member-card-modal max-width-page">
    <modal is-close class="has-margin-top-20"
    >
      <AccountMemberCardModalHeader/>
      <div class="has-padding-15">
        <MemberCard/>
        <div class="has-margin-top-25">
          <div class="has-text-black40 _fs-14 has-text-weight-medium">
            {{$t('memberCardModal.expirationDate', {expiredAt})}}
          </div>
          <div class="is-flex justify-space-between align-items-center has-margin-top-5">
            <div class="is-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g transform="translate(-52.000000, -537.000000) translate(52.000000, 535.000000)">
                  <g>
                    <path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 2c.552 0 1 .448 1 1v5.586l2.707 2.707c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0l-3-3C11.105 12.52 11 12.265 11 12V6c0-.552.448-1 1-1z" transform="translate(0.000000, 2.000000)"/>
                  </g>
                </g>
              </svg>
              <countdown :time="20 * 60 * 1000">
                <template slot-scope="props">
                  <span class="has-margin-left-10 _fs-24 has-text-primary align-items-center">
                    {{ props.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}:{{ props.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}
                  </span>
                </template>
              </countdown>
            </div>
            <SmallButton type="outline" @click.native="$router.push({ name: 'RenewMembership' })">{{$t('memberCardModal.renewMemberCard')}}</SmallButton>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Modal from '@/components/base/Modal.vue'
import SmallButton from '@/components/base/SmallButton.vue'
import MemberCard from '@/components/MemberCard.vue'
import AccountMemberCardModalHeader from '@/components/AccountMemberCardModalHeader.vue'

export default {
  props: {
    error: {
      type: String
    }
  },
  components: {
    Modal,
    MemberCard,
    AccountMemberCardModalHeader,
    SmallButton
  },
  computed: {
    ...mapGetters('Auth', [
      'pointReceive',
      'userPackage',
      'eventPackage',
      'currentMemberCard',
      'nextMemberCard',
      'nextPointLevel',
      'remainPointNextLevel',
      'memberCards',
      'progressMemberLevel'
    ]),
    expiredAt () {
      if (this.userPackage && this.userPackage.expired_at) {
        return moment(this.userPackage.expired_at).locale(this.$i18n.locale).format('DD MMM YYYY')
      } else {
        return ''
      }
    }
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('input', '')
      this.$emit('onClose')
    },
    onInputChange (newValue) {
      this.$emit('input', newValue)
    },
    submit () {
      this.resetScroll()
      this.$emit('submit')
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.member-card-modal {
  svg {
    path {
      fill: $primary !important;
    }
  }
}
</style>
