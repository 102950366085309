<template>
  <div class="member-card-wrapper">
    <div class="member-card" :style="{
      backgroundImage: `url('${currentMemberCard && currentMemberCard.url || ''}')`
    }">
      <div class="is-flex flex-column justify-space-between is-full-height align-items-flex-start">
        <div class="is-flex justify-flex-end align-items-flex-start is-full-width">
          <!-- <img src="@/assets/img/logo-white/logo-pt2.png" class="logo"/> -->
          <div class="_fs-16 has-text-white _fw-500 opacity-87">{{$t('common.level', { level: $i18n.locale === 'th' ? currentMemberCard.name || '' : currentMemberCard.name_en || '' })}}</div>
        </div>
        <div class="has-text-white">
          <div class="opacity-87 _fs-12">{{ $t('common.memberId', { memberId: (userPackage && userPackage.code) || '-' })}}</div>
          <div class="_fs-18 _fw-500">{{user && user.first_name || ''}} {{user && user.last_name || ''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', [
      'currentMemberCard',
      'userPackage'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.member-card-wrapper {
  padding-top: 56.25%;
  position: relative;
  .logo {
    width: 160px;
  }
  .member-card {
    border-radius: 12px;
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3); */
    /* background-image: $member-card; */
    background: white;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 18px 16px 16px 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
