<template>
  <div class="menu-modal">
    <div class="is-flex justify-center align-items-center menu-header">
      <div class="icon-wrapper" @click="$parent.close()">
        <img class="" src="@/assets/icon/x.svg" width="24"/>
      </div>
      <img src="@/assets/img/logo-white/logo-ptn.png" class="logo"/>
    </div>
    <div class="flex-1 is-flex flex-column">
      <div class="flex-1">
        <div class="menu" @click="$parent.close()">{{$t('menu.home')}}</div>
        <div class="menu" @click="changeRoute('Account')">{{$t('menu.account')}}</div>
        <div class="menu" @click="changeRoute('MyCoupon')">{{$t('menu.myCoupon')}}</div>
        <div class="menu" @click="changeRoute('HistoryCoupon')">{{$t('menu.history')}}</div>
      </div>
      <div class="is-flex justify-center flex-column align-items-center">
        <span class="has-text-white _fs-12 has-margin-bottom-10 has-margin-top-10">{{$t('common.changeLanguage')}}</span>
        <ChangeLanguage is-large/>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeLanguage from '@/components/ChangeLanguage.vue'
export default {
  name: 'MenuModal',
  components: {
    ChangeLanguage
  },
  methods: {
    changeRoute (routeName) {
      this.$router.push({ name: routeName })
      this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.menu-modal{
  background: $primary;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .logo {
    width: 160px;
  }
  .menu-header {
    position: relative;
  }
  .icon-wrapper {
    height: 40px;
    width: 40px;
    border: 1px solid $grey97;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999999px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .menu {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid white;
    text-align: center;
    color: white;
    font-size: 3.0rem;
    font-weight: 500;
  }
}
</style>
