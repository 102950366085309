<template>
  <div class="account-member-card-modal-header">
    <div class="is-flex align-items-center">
      <div class="avatar-wrapper">
        <img class="img-profile"
          :src="user && user.line_picture_url || 'https://www.w3schools.com/w3images/avatar2.png'"
          @error="replaceByDefault"
        />
      </div>
      <div class="has-margin-left-15 flex-1">
        <span class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5">{{$t('common.level', { level: $i18n.locale === 'th' ? currentMemberCard.name || '' : currentMemberCard.name_en || '' })}}</span>
        <div class="has-text-black _fs-16 _fw-500 has-margin-top-5">{{user && user.first_name || ''}} {{user && user.last_name || ''}}</div>
        <div class="_fs-12 has-text-black has-margin-top-5">{{ $t('common.memberId', { memberId: (userPackage && userPackage.code) || '-' })}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', [
      'currentMemberCard',
      'userPackage'
    ])
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = 'https://www.w3schools.com/w3images/avatar2.png'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.account-member-card-modal-header {
  padding: 0 16px;
  .avatar-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: $greyd8;
    .img-profile {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
</style>
