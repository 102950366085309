<template>
  <div class="expired-member-card-wrapper">
    <div class="expired-member-card">
      <div class="is-flex justify-center align-items-center is-full-width has-text-white is-full-height is-relative flex-column">
        <div class="is-flex justify-flex-start align-items-center">
          <img src="@/assets/icon/plus-circle.svg"/>
          <span>{{$t('expiredMemberCard.addMemberCard')}}</span>
        </div>
        <div class="is-bottom is-absolute">{{$t('expiredMemberCard.expired')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', [
      'currentMemberCard',
      'userPackage'
    ])
  }
}
</script>

<style lang="scss" scoped>
.expired-member-card-wrapper {
  padding-top: 56.25%;
  position: relative;
  .logo {
    width: 160px;
  }
  .expired-member-card {
    border-radius: 12px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background-color: #989898;
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
