<template>
  <div class="renew-member-modal max-width-page">
    <modal is-close class="has-margin-top-20"
    has-padding
    >
      <div class="has-text-center
      _fs-30
      _lh-1-2x
      has-text-primary
      has-text-centered
      has-text-weight-medium">
        {{$t('renewModal.register')}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">{{$t('renewModal.registerDetail')}}</div>
      <div class="has-margin-top-20">
        <Input :placeholder="$t('renewModal.registerCode')"
          :value="registrationCode"
          @input="onInputChange"
          @blur="resetScroll()"
          >
        </Input>
        <div
          style="font-size: 16px"
          v-if="error"
          class="error has-text-centered has-margin-top-5 has-text-weight-light">
          {{error}}
        </div>
      </div>
      <div class="has-margin-top-30 is-flex justify-center">
        <RoundButton @click="submitEnterCode()">
          {{$t('common.done')}}
        </RoundButton>
      </div>
    </modal>
    <modal class="has-margin-top-20"
    has-padding
    >
      <div class="has-text-center
      _fs-30
      _lh-1-4x
      has-text-primary
      has-text-centered
      has-text-weight-medium">
        {{ isPending ? $t('renewModal.pendingRegisterCode') : $t('renewModal.newMemberCard')}}</div>
      <div class="_fs-16 has-text-black has-text-centered has-margin-top-10">
         {{ isPending ? $t('renewModal.contactSupport') : $t('renewModal.requestNewMemberCard')}}
      </div>
      <div class="has-margin-top-20">
        <div
          style="font-size: 16px"
          v-if="error"
          class="error has-text-centered has-margin-top-5 has-text-weight-light">
          {{error}}
        </div>
      </div>
      <div class="has-margin-top-30 is-flex justify-center">
        <RoundButton @click="requestRegistrationCode()">
          {{ isPending ? $t('renewModal.btnContactSupport') : $t('renewModal.btnRequestRegisterCode')}}
        </RoundButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/base/Modal.vue'
import Input from '@/components/base/Input.vue'
import RoundButton from '@/components/base/RoundButton.vue'

export default {
  props: {
    registrationCode: {
      type: String,
      required: true
    },
    isPending: {
      type: Boolean,
      default: false,
      required: true
    },
    error: {
      type: String
    }
  },
  components: {
    Modal,
    Input,
    RoundButton
  },
  methods: {
    close () {
      this.$parent.close()
      this.$emit('input', '')
      this.$emit('onClose')
    },
    onInputChange (newValue) {
      this.$emit('input', newValue)
    },
    submitEnterCode () {
      this.resetScroll()
      this.$emit('submit')
    },
    requestRegistrationCode () {
      if (this.isPending) {
        this.$emit('contactSupport')
      } else {
        this.$emit('requestCode')
      }
    },
    resetScroll () {
      const y = document.documentElement.scrollTop
      window.scrollTo(0, y - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.renew-member-modal {
}
.profile-wrapper {
  padding: 0 14px;
}
</style>
